.card{
    margin: 2rem;
}
.goal-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.field{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

.dialog-footer{
    display: flex;
    gap: 1rem;
    justify-content: end;
}

.chip-container{
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.p-multiselect-panel .p-multiselect-items {
    padding: inherit!important;
}

.action-container{
    display: flex;
    gap: 1rem;
}

.p-dialog-footer{
    display: flex;
    gap: 1rem;
    justify-content: end;
}

.p-chip {
    display: inline-flex;
    align-items: center;
    flex-direction: row-reverse;
    gap: inherit;
    padding-right: 0;
}

.code-link:hover{
    cursor: pointer;
    text-decoration: underline;
    color: #06b6d4;
}

.sticky{
    position: sticky;
    top: 0;
    z-index: 99;
    margin-bottom: 1rem;
}